.time-picker-container {
    margin-left: 20px;
    margin-right: 20px;
}

.booking-container {
    border: 1px solid #2EA279;
    border-radius: 12px;
    padding: 40px
}

/* ====== Preview ====== */
#previewContainer {
    position: fixed;
    z-index: 1000;
    height: calc(100vh - 64px);
    width: 100%;
    top: 64px;
    left: 0;
    padding-top: 80px;
    background: white;
}

@media only screen and (max-width: 600px) {
    .booking-container {
        border-radius: 12px;
        padding: 10px;
    }
    .time-picker-container {
        margin-left: 10px;
        margin-right: 10px;
    }
    .react-datepicker__input-container input {
        height: 44px;
        font-size: 1.2em;
    }

    .link-building {
        width: 100%;
    }

    .btn-preview {
        width: 100%;
    }
}