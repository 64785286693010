#header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

#body-container {
    width: 80%;
    max-width: 920px;
    margin-left: auto;
    margin-right: auto;
}
