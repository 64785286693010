p {
    margin: 0;
}

.time-row {
    background-color: rgb(241, 241, 241);
    position: relative;
    margin-left: 60px;
    padding: 20px;
    border-top: 1px solid #999;
    height:80px
}
.time-row:last-child {
    background-color: rgb(255, 255, 255);
}
.time-row:last-child .user-list {
    display: none;
}

.display-time {
    position:absolute;
    left: -50px;
    top: -10px;
    font-size: 0.8em;
    text-align: right;
}

.time-picker-btn {
    position: relative;
    height: 24px;
    width: 32px;
    margin-bottom: 32px;
}
.btn-inactive {
    background-color: #999;
}
.btn-active {
    background-color: rgb(47, 236, 129);
}
.picker-left {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    margin-left: 2px;
    
}
.picker-right {
    border-left: 1px solid #feffff;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    margin-right: 2px;
}
.timepicker-time {
    position: absolute;
    font-size: 0.75em;
    left: -22px;
    bottom: -20px;
    width: 72px;
}


.user-tag {
    position: relative;
    display: inline-block;
    background: white;
    border: 1px solid;
    margin-left: 10px;
    margin-bottom: 10px;
    margin-right: 30px;
    padding: 4px 10px;
}

.empty-slot {
    display: flex;
}

.empty-slot .btn {
    margin-left: 20px;
}

.btn-close-slot {
    color: rgb(68, 184, 22);
    border: 1px solid rgb(68, 184, 22);
}

.btn-remove {
    position: absolute;
    right: -20px;
    top: 0;
}

.btn:hover {
    cursor: pointer;
}

