.open {
    background-color: rgb(47, 236, 129);
}
.open:hover {
    cursor: pointer;
}
.close {
    color:rgb(146, 146, 146);
    background-color: rgb(210, 210, 210);
}
.close:hover {
    cursor:not-allowed;
}
.time-btn {
    height: 32px;
    width: 72px;
    margin: 32px 0px;
    position: relative;
    border: none;
    border-left: 1px solid #006633;
}
.time-label {
    font-family: Arial, Helvetica, sans-serif;
    position: absolute;
    left: -20px;
    top: -30px;
    font-size: 12px;
}
.btn-end .time-label {
    top: -32px;
    font-size: 12px;
}
.btn-end {
    height: 32px;
    width: 72px;
    margin: 32px 0px;
    position: relative;
}
.selected {
    background-color: rgb(255, 215, 36);
}

@media only screen and (max-width: 600px) {
    .time-label {
        font-size: 0.9em;
    }
}